import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Accordion,
  AccordionSummary,
  Box,
  Button,
  Grid,
  ListItem,
  makeStyles,
  Theme,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { green, orange, red } from "@material-ui/core/colors";
import LensIcon from "@material-ui/icons/Lens";
import BugReportIcon from "@material-ui/icons/BugReport";
import { TextWithElipisis } from "./styled";
import { getFilename, getParsedDate, isExpired } from "../utils";
import { MxheroShare } from "@dashboard-v3/api";
import styled from "styled-components";

interface StorageShareItemProps {
  item: MxheroShare;
  loading: boolean;
  onRevoke: (id: string) => void;
}

export default function MxheroShareItem(props: StorageShareItemProps) {
  const { t } = useTranslation("storageShares");
  const history = useHistory();
  const { item, onRevoke, loading } = props;
  const isAvailable = item.expiresAt ? !isExpired(item.expiresAt) : true;
  const classes = useStyles({ isAvailable });
  const statusText = Boolean(item.malware)
    ? t("list.item.status.malware")
    : isAvailable
    ? t("list.item.status.enabled")
    : t("list.item.status.expired");

  const onEdit = () => {
    history.push({
      pathname: `/mxhero-shares/${item.shareId}`,
      state: { selectedShare: item, shareId: item.shareId },
    });
  };

  const isMalware = Boolean(item.malware) && !item.malware.allowedToUse;
  const statusColor = isAvailable ? green.A400 : orange.A200;

  return (
    <ListItem disableGutters>
      <Accordion className={classes.accordion} expanded={false}>
        <AccordionSummary style={{ cursor: "default" }}>
          <Tooltip title={statusText}>
            {isMalware ? (
              <Box
                alignItems="center"
                display="flex"
                paddingRight="22px"
                paddingLeft="7px"
              >
                <BugReportIcon
                  htmlColor={red.A200}
                  style={{ fontSize: "1.2rem" }}
                />
              </Box>
            ) : (
              <StatusIconContainer>
                <StatusIcon htmlColor={statusColor} />
              </StatusIconContainer>
            )}
          </Tooltip>

          <Grid container style={{ rowGap: 4 }}>
            <Grid item sm={12} className={classes.gridItem} alignItems="center">
              <Tooltip title={item.itemId}>
                <TextWithElipisis display="inline" style={{ fontSize: "1rem" }}>
                  {getFilename(item)}
                </TextWithElipisis>
              </Tooltip>
            </Grid>
            <Grid item lg={4} className={classes.gridItem} alignItems="center">
              <Typography
                className={classes.gridItemTxt}
                color="textSecondary"
                component="span"
                variant="subtitle2"
              >
                {t("list.item.owner")}
              </Typography>
              <Tooltip title={item.owner}>
                <TextWithElipisis display="inline" variant="subtitle2">
                  {item.owner}
                </TextWithElipisis>
              </Tooltip>
            </Grid>

            <Grid item lg={4} className={classes.gridItem} alignItems="center">
              <Typography
                className={classes.gridItemTxt}
                color="textSecondary"
                component="span"
                variant="subtitle2"
              >
                {item.canDownload
                  ? t("list.item.isDownloadable")
                  : t("list.item.isNotDownloadable")}
              </Typography>
            </Grid>
            <Grid item lg={4} className={classes.gridItem} alignItems="center">
              <Typography
                className={classes.gridItemTxt}
                color="textSecondary"
                component="span"
                variant="subtitle2"
              >
                {!item.expiresAt
                  ? t("list.item.noExpiration")
                  : t("list.item.expiresAt", {
                      date: getParsedDate(item.expiresAt),
                    })}
              </Typography>
            </Grid>
          </Grid>
          <Button
            className={classes.revokeBtn}
            size="small"
            variant="outlined"
            color="primary"
            onClick={() => onRevoke(item.shareId)}
            disabled={loading || isExpired(item.expiresAt)}
          >
            {t("list.item.expireBtn")}
          </Button>
          <Button
            className={classes.editBtn}
            size="small"
            variant="outlined"
            color="default"
            onClick={() => onEdit()}
          >
            {t("common:edit")}
          </Button>
        </AccordionSummary>
      </Accordion>
    </ListItem>
  );
}

const useStyles = makeStyles<
  Theme,
  { isAvailable: boolean },
  | "accordion"
  | "gridItem"
  | "gridItemTxt"
  | "statusContainer"
  | "revokeBtn"
  | "editBtn"
  | "overrides"
  | "itemName"
>({
  accordion: { width: "100%" },
  gridItem: { display: "flex", gap: "5px" },
  itemName: { fontSize: "1rem" },
  gridItemTxt: { fontSize: "13px" },
  statusContainer: {
    fontSize: "0.7rem",
  },
  revokeBtn: { alignSelf: "center" },
  editBtn: { alignSelf: "center", marginLeft: "10px" },
  overrides: {
    MuiExpansionPanelSummary: {
      root: {
        "&:hover:not(.Mui-disabled)": {
          cursor: "default",
        },
      },
    },
  },
});

const StatusIcon = styled(LensIcon)`
  margin-left: 10px;
  font-size: 0.8rem;
  margin-right: 6px;
`;

const StatusIconContainer = styled.div`
  display: flex;
  align-items: center;
  padding-right: 18px;
`;
