import { Box, MenuItem, Select, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { DatasourceForm } from ".";

type ValueType = DatasourceForm["params"]["useAsKey"];

type Params = {
  title: string;
  value: ValueType;
  fileOrFolder: DatasourceForm["params"]["type"];
  onChange: (newValue: ValueType) => void;
};
export default function SelectUseAs({
  title,
  value,
  fileOrFolder,
  onChange,
}: Params) {
  const { t } = useTranslation("datasets");

  return (
    <Box display="flex" alignItems="center" mt={2}>
      <Box mr={2} ml={1}>
        <Typography>{title}</Typography>
      </Box>
      <Box>
        <Select
          variant="outlined"
          value={value}
          onChange={event => {
            onChange(event.target.value as "id" | "name");
          }}
        >
          <MenuItem value="id">
            {t("datasourceForm.theID", {
              type: t(`datasourceForm.type.${fileOrFolder}`),
            })}
          </MenuItem>
          <MenuItem value="name">
            {t("datasourceForm.theName", {
              type: t(`datasourceForm.type.${fileOrFolder}`),
            })}
          </MenuItem>
        </Select>
      </Box>
    </Box>
  );
}
