/* eslint-disable react/prop-types */
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import {
  Accordion,
  Box,
  Grid,
  LinearProgress,
  ListItem,
  ListItemText,
  Typography,
  makeStyles,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Details, { getEmailData } from "./Details";
import { fetchEventDetails } from "./api";
import {
  StyledAccordionSummary,
  StyledAccordionDetails,
  PropertyData,
} from "./Styled";
import MailDeliveredData from "./MailDeliveredData";

import { TracingEvent, TracingEventDetails } from "@dashboard-v3/api";

interface ItemProps {
  emailEvent: TracingEvent;
}

export default function Item({ emailEvent }: ItemProps) {
  const { createdAt, data, msgUID } = emailEvent;
  const { t } = useTranslation("tracing");
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [itemDetails, setItemDetails] = useState<TracingEventDetails[]>([]);

  const fetchItemDetails = async () => {
    setLoading(true);
    try {
      const result = await fetchEventDetails(msgUID);
      setItemDetails(result);
    } catch (error) {
      enqueueSnackbar(t("error.fetchItemEvents"), {
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  function toggle(_, isExpanded) {
    setExpanded(isExpanded);
    if (isExpanded && !isLoading) {
      fetchItemDetails();
    }
  }

  const emailData = getEmailData(itemDetails);

  return (
    <ListItem disableGutters data-testid="tracing__result-list-item">
      <Accordion
        className={classes.accordion}
        onChange={toggle}
        expanded={expanded}
      >
        <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
          <ListItemText
            primary={
              <>
                <Typography
                  color="textSecondary"
                  className={classes.createdText}
                  variant="body2"
                >
                  {new Date(createdAt).toLocaleString()}
                </Typography>
                <Grid container>
                  <Grid item lg={6}>
                    <Typography
                      color="textSecondary"
                      component="span"
                      variant="body2"
                    >
                      {t("from")}:
                    </Typography>
                    <PropertyData className={classes.data}>
                      {data.from}
                    </PropertyData>
                  </Grid>
                  <Grid item lg={6}>
                    <Typography
                      color="textSecondary"
                      component="span"
                      variant="body2"
                    >
                      {t("recipient")}
                    </Typography>
                    <PropertyData className={classes.data}>
                      {data.recipient}
                    </PropertyData>
                  </Grid>
                </Grid>
              </>
            }
            secondary={
              <>
                <Typography
                  color="textSecondary"
                  component="span"
                  variant="body2"
                >
                  {t("subject")}:
                </Typography>
                <Typography component="span" className={classes.subject}>
                  {data.subject}
                </Typography>
              </>
            }
          />
        </StyledAccordionSummary>
        <StyledAccordionDetails className={classes.accordionDetails}>
          {isLoading && (
            <Box>
              <LinearProgress />
            </Box>
          )}
          {!isLoading && !!itemDetails.length && (
            <>
              <Grid container spacing={2}>
                <MailDeliveredData data={emailData} />
              </Grid>
              <Details details={itemDetails} />
            </>
          )}
        </StyledAccordionDetails>
      </Accordion>
    </ListItem>
  );
}

const useStyles = makeStyles({
  createdText: { marginBottom: "0.4rem" },
  subject: { marginLeft: "5px", fontWeight: 500 },
  data: { marginLeft: "5px", fontWeight: 500, display: "inline" },
  accordion: { width: "100%" },
  accordionDetails: { display: "block" },
});
