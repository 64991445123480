/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, ListSubheader, Typography } from "@material-ui/core";
import SelectField from "components/Forms/SelectField";
import { FieldSkeleton } from "components/SkeletonLoading";
import { Label, Description, OptionGroup } from "components/Styled";
import { StyledDivider, StyledInput, StyledMenuItem } from "./Styled";
import { useRuleContext } from "../CreateOrEdit/context/ruleContext";
import {
  getStorage,
  isPresetOption,
  isSearchOption,
  isSecurityOption,
  OptionTypes,
} from "./utils";

import { SecurityOption } from "./types";

interface SecuritySelectorProps {
  options: SecurityOption[];
}

export default function SelectSecurityOptions({
  options,
}: SecuritySelectorProps) {
  const { t } = useTranslation(["rules", "securityDefinitions"]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const { state, dispatch } = useRuleContext();
  const storage = getStorage(state.rule);
  const loading = Boolean(!options);

  const getSecurityLabel = () => {
    let label = "";
    if (storage.linkSecurity && options.length) {
      const { id } = storage.linkSecurity;
      const match = findByKey(options, id);
      label = t(match?.label) || "";
    }
    return label;
  };

  const getFilteredSecurityOptions = (): SecurityOption[] =>
    options.filter(({ type, text, provider, value }) => {
      const { targetType } = state.rule;

      if (["ARCHIVING_ADDRESS", "MAIL_BOX"].includes(targetType)) {
        const allowedOpts = ["PRESET:OPEN_LINKS", "PRESET:ORGANIZATION_LINKS"];
        return type === OptionTypes.EMPTY || allowedOpts.includes(value);
      }

      const shouldFilter =
        !isPresetOption(type) &&
        !isSearchOption(type) &&
        type !== OptionTypes.EMPTY;

      if (shouldFilter) {
        const matchesSearch = text
          .toLowerCase()
          .includes(searchTerm.toLowerCase());
        return provider === storage.provider && matchesSearch;
      }
      return true;
    });

  const getSecurityOptionsValue = () => {
    const id = storage.linkSecurity?.id;
    if (!id) return "";
    const match = findByKey(options, id);
    return match ? match.key : "";
  };

  const keyToLabel = (key): string => {
    const option = findByKey(options, key as string);

    if (!option) return t("cloudStorage.security.empty");

    if (option.type === "PRESET") {
      return t(`securityDefinitions:presets.${option.key}.name`, option.text);
    }

    return option.text;
  };

  const handleSecurityOptions = e => {
    const { value: optionKey } = e.target;
    let linkSecurity = null;
    const option = findByKey(options, optionKey);

    if (option && option.type !== OptionTypes.EMPTY) {
      const computedType =
        option.type === OptionTypes.PRESET
          ? OptionTypes.DEFINITION
          : option.type;

      linkSecurity = { id: option.key, type: computedType };
    }

    dispatch({
      type: "checkFolderCollaboration",
      payload: { securityOption: option },
    });

    dispatch({
      type: "updateStep",
      payload: {
        step: "cloudStorage",
        changes: {
          linkSecurity,
        },
      },
    });
  };

  if (loading) {
    return <FieldSkeleton withLabel testId="SelectSecurityOptions__loading" />;
  }

  return (
    <OptionGroup data-testid="SelectSecurityOptions">
      <Label wording={t("cloudStorage.security.title")} />
      <Box mt={2}>
        <SelectField<SecurityOption>
          name="securityOptions"
          label={getSecurityLabel()}
          allowEmpty={storage.provider !== "MXHERO_STORAGE"}
          emptyOption={t("securityDefinitions:access.NONE")}
          autoFocus={false}
          disabled={!storage?.provider}
          options={getFilteredSecurityOptions()}
          onClose={() => setSearchTerm("")}
          onChange={handleSecurityOptions}
          value={getSecurityOptionsValue()}
          renderValue={keyToLabel}
          renderOption={opt => {
            if (isSecurityOption(opt.type)) {
              return (
                <StyledMenuItem key={opt.key} value={opt.key}>
                  <Typography variant="caption">{t(opt.label)}</Typography>
                  <Typography>
                    {t(`securityDefinitions:presets.${opt.key}.name`, opt.text)}
                  </Typography>
                  {opt.description && (
                    <Description variant="caption">
                      {t(opt.description)}
                    </Description>
                  )}
                </StyledMenuItem>
              );
            }

            if (isSearchOption(opt.type)) {
              return (
                <ListSubheader key={opt.key} disableSticky={true}>
                  <StyledDivider />
                  <StyledInput
                    label={t(opt.label)}
                    onClick={e => e.stopPropagation()}
                    onKeyDown={e => e.stopPropagation()}
                    onChange={e => setSearchTerm(e.target.value)}
                    value={searchTerm}
                  />
                </ListSubheader>
              );
            }
          }}
        />
      </Box>
    </OptionGroup>
  );
}

function findByKey(options: SecurityOption[], key: string) {
  return options.find(option => option.key === key);
}
