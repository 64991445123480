import { Rule } from "pages/Rules/types";
import { Action } from "./action";
import { State } from "./types";
import initSteps from "./initSteps";
import changeTargetType from "./reducers/changeTargetType";
import { confirmStep } from "./reducers/confirmStep";
import { updateStep } from "./reducers/updateStep";
import { updateFilterTarget } from "./reducers/updateFilterTarget";
import { updateFilterAction } from "./reducers/updateFilterAction";
import { saveRule } from "./reducers/saveRule";
import { updateRuleLabels } from "./reducers/updateRuleLabels";
import { saveRuleFail } from "./reducers/saveRuleFail";
import { asyncValidationEnds } from "./reducers/asyncValidationEnds";
import { updateWebhookProcessing } from "./reducers/updateWebhookProcessing";
import initChecks from "./initChecks";
import { checkUnmanagedFlow } from "./reducers/checkUnmanagedFlow";
import { updateStorageManagement } from "./reducers/updateStorageManagement";
import { updateIncludeFolders } from "./reducers/updateIncludeFolders";
import { updateExcludeFolders } from "./reducers/updateExcludeFolders";
import { updateMoreActions } from "./reducers/updateMoreActions";
import { checkFolderCollaboration } from "./reducers/checkFolderCollaboration";
import { deepClone } from "@dashboard-v3/shared";

declare global {
  interface Window {
    ruleState: State;
  }
}

export function reducer(state: State, action: Action): State {
  console.groupCollapsed(action.type);
  console.log("> ", action);

  const newState = executeReducer(state, action);

  if (newState) {
    console.log("< ", newState);
    console.groupEnd();
    window.ruleState = deepClone(newState);
    return newState;
  } else {
    console.log("[WARNING] no changes or type not found");
    console.groupEnd();
    return state;
  }
}

function executeReducer(state: State, action: Action) {
  switch (action.type) {
    case "updateRuleLabels":
      return updateRuleLabels(state, action.payload);

    case "changeTargetType":
      return changeTargetType(state, action.payload);

    case "confirmStep":
      return confirmStep(state, action.payload);

    case "updateStep":
      return updateStep(state, action.payload);

    case "updateFilterTarget":
      return updateFilterTarget(state, action.payload);

    case "updateFilterAction":
      return updateFilterAction(state, action.payload);

    case "updateWebhookProcessing":
      return updateWebhookProcessing(state, action.payload);

    case "updateStorageManagement":
      return updateStorageManagement(state, action.payload);

    case "updateMoreActions":
      return updateMoreActions(state, action.payload);

    case "asyncValidationEnds":
      return asyncValidationEnds(state, action.payload);

    case "checkUnmanagedFlow":
      return checkUnmanagedFlow(state, action.payload);

    case "checkFolderCollaboration":
      return checkFolderCollaboration(state, action.payload);

    case "saveRule":
      return saveRule(state, action.payload);

    case "saveRuleFail":
      return saveRuleFail(state, action.payload);

    case "updateIncludeFolders":
      return updateIncludeFolders(state, action.payload);

    case "updateExcludeFolders":
      return updateExcludeFolders(state, action.payload);

    default:
      throw new Error("Some dispatch type was omited");
  }
}

export function initialState(rule: Rule): State {
  const isEdit = Boolean(rule.id);
  const { steps, currentStep } = initSteps(rule);

  if (isEdit) {
    return {
      asyncStatus: "idle",
      rule,
      type: rule.actionType,
      currentStep,
      steps,
      filters: initFilterState(),
      canSave: true,
      remaining: [],
      checks: initChecks(rule),
      webhookStatus: {
        isValid: false,
        foldersInUse: [],
        missingFolder: "",
      },
    };
  }

  return {
    asyncStatus: "idle",
    rule,
    type: rule.actionType,
    currentStep,
    steps,
    filters: initFilterState(),
    canSave: false,
    remaining: ["name", "steps"],
    checks: initChecks(rule),
    webhookStatus: {
      isValid: false,
      foldersInUse: [],
      missingFolder: "",
    },
  };
}

const initFilterState = () => ({
  actionFilter: { valid: true, errors: {} },
  targetFilter: { valid: true, errors: {} },
  targetFolders: { valid: true, errors: {} },
});
