import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import {
  ListItem,
  AccordionSummary,
  ListItemText,
  Box,
  Typography,
  Button,
} from "@material-ui/core";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ConfirmDelete from "components/ConfirmDelete";
import {
  ListItemAccordion,
  StyledAccordionDetails,
} from "components/List/StyledComponents";
import { useDependencyModal } from "components/DependencyModal";
import { getFormattedTimeStamp } from "utils/dateUtils";
import apiRequest from "utils/apiRequestWithErrorCode";
import { DataSetWithDatasource } from "@dashboard-v3/api";
import { KeyValueContainer, KeyValueItem } from "../Styled";
// import { green } from "@material-ui/core/colors";

interface ItemProps {
  content: DataSetWithDatasource;
  setLoading: Dispatch<SetStateAction<"ALL" | "MORE" | "ITEM">>;
  setLoadingItem: Dispatch<SetStateAction<string>>;
  fetchDatasets(): Promise<void>;
}

export default function DataSetItem({
  content,
  fetchDatasets,
  setLoading,
  setLoadingItem,
}: ItemProps) {
  const { id, name, updatedAt, rows } = content;
  const { t } = useTranslation("datasets");
  const { enqueueSnackbar } = useSnackbar();
  const { errorHandler } = useDependencyModal();

  const deleteDataSet = async (id: string) => {
    setLoading("ITEM");
    setLoadingItem(id);
    try {
      await apiRequest("DELETE", `/datasets/${id}`);
      await fetchDatasets();

      enqueueSnackbar(t("deleteSuccess", { name: content.name }), {
        variant: "success",
      });
    } catch (e) {
      errorHandler(e, t(`errors.deleteError`));
    } finally {
      setLoading(null);
      setLoadingItem(null);
    }
  };

  return (
    <ListItem disableGutters>
      <ListItemAccordion>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <ListItemText
            primary={
              <>
                <Box marginY="5px">
                  <Typography display="inline" style={{ fontWeight: "500" }}>
                    {name}
                  </Typography>
                </Box>
                <Box marginBottom="5px">
                  <Typography color="textSecondary" variant="body2">
                    {t("updatedAt", {
                      date: getFormattedTimeStamp(updatedAt, true),
                    })}
                  </Typography>
                </Box>
              </>
            }
          />
          <Box
            display="flex"
            marginRight="10px"
            justifyContent="space-between"
            alignItems="center"
            gridGap="10px"
          >
            {/*
            {content.syncEnabled && (
              <Box color={green[400]}>{t("listItem.syncEnabled")}</Box>
            )}
            
            <Button
              variant="outlined"
              size="small"
              color="default"
              href={
                content.datasourceTaskId
                  ? `/datasources/${content.datasourceTaskId}`
                  : `/datasets/${content.id}/datasources`
              }
              onClick={event => event.stopPropagation()}
            >
              {content.datasourceTaskId
                ? t("listItem.showSyncs")
                : t("listItem.initSync")}
            </Button>
            */}
            <Button
              href={`/datasets/edit/${content.id}`}
              data-testid="datasets_edit-item-btn"
              variant="outlined"
              size="small"
              color="default"
              onClick={event => event.stopPropagation()}
            >
              {t("common:edit")}
            </Button>

            <ConfirmDelete
              message={t("listItem.confirmDeletion", { name })}
              variant="outlined"
              size="small"
              onAccept={() => deleteDataSet(id)}
            />
          </Box>
        </AccordionSummary>
        <StyledAccordionDetails>
          <KeyValueContainer>
            {Object.keys(rows).map(key => (
              <KeyValueItem key={key}>
                <div>
                  <span>{t("listItem.key")}</span>
                  {key}
                </div>
                <div>
                  <span>{t("listItem.value")}</span>
                  {rows[key]}
                </div>
              </KeyValueItem>
            ))}
          </KeyValueContainer>
        </StyledAccordionDetails>
      </ListItemAccordion>
    </ListItem>
  );
}
