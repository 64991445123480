import { Box, MenuItem, Select } from "@material-ui/core";
import { OptionTitle } from "components/Styled";
import { useTranslation } from "react-i18next";
import { DatasourceForm } from ".";

type Params = {
  value: DatasourceForm["repeatEvery"];
  onChange: (value: DatasourceForm["repeatEvery"]) => void;
};
export default function SelectRepeat({ value, onChange: emitChange }: Params) {
  const { t } = useTranslation("datasets");

  return (
    <Box display="flex" alignItems="center" mt={2}>
      <Box mr={2}>
        <OptionTitle>{t("datasourceForm.repeatLabel")}</OptionTitle>
      </Box>

      <Select
        variant="outlined"
        value={(value ?? 0).toString()}
        onChange={event => {
          const newValue = parseInt(
            event.target.value as string,
            10
          ) as DatasourceForm["repeatEvery"];

          emitChange(newValue);
        }}
      >
        <MenuItem value="0">{t("datasourceForm.noRepeat")}</MenuItem>
        <MenuItem value="6">{t("datasourceForm.every6h")}</MenuItem>
        <MenuItem value="12">{t("datasourceForm.every12h")}</MenuItem>
        <MenuItem value="24">{t("datasourceForm.every24h")}</MenuItem>
      </Select>
    </Box>
  );
}
