import { useParams, useHistory } from "react-router-dom";
import useSWR, { useSWRConfig } from "swr";
import { fetcher } from "utils/apiRequestWithErrorCode";
import Form, { DatasourceForm } from "./Form";
import { DatasourceTask } from "@dashboard-v3/api/src/types/datasets";
import ListExecutions from "./ListExecutions";
import { Box, LinearProgress, Paper } from "@material-ui/core";
import { useSnackbar } from "notistack";
import SectionHeader from "components/SectionHeader";
import { Button } from "components/Forms/StyledComponents";
import { useTranslation } from "react-i18next";

export default function EditDatasource() {
  const { datasourceId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { t } = useTranslation("datasets");

  const { mutate } = useSWRConfig();

  const { data: datasource, mutate: mutateDatasource } = useSWR<DatasourceTask>(
    `/datasources/${datasourceId}`,
    fetcher,
    {
      revalidateOnFocus: false,
    }
  );

  if (!datasource) {
    return (
      <>
        <SectionHeader
          title="Datasource"
          sideBtn={
            <Button
              wording="Back to list"
              onClick={() => history.push("/datasets")}
            />
          }
        />
        <Paper style={{ padding: "12px 16px" }}>
          <LinearProgress />
        </Paper>
      </>
    );
  }

  const form: DatasourceForm = {
    id: datasource.id,
    action: datasource.action,
    datasetId: datasource.datasetId,
    repeatEvery: datasource.isScheduled
      ? ((datasource.schedule?.everyMins / 60) as 6 | 12 | 24)
      : 0,
    params: datasource.params,
  };

  return (
    <>
      <SectionHeader
        title="Datasource"
        sideBtn={
          <Button
            wording="Back to list"
            onClick={() => history.push("/datasets")}
          />
        }
      />
      <Form
        key={`${datasource.id}-${datasource.isScheduled}`}
        data={form}
        onSave={task => {
          mutateDatasource();

          if (!task.isScheduled) {
            mutate(`/datasources/${task.id}/executions`);
            enqueueSnackbar(t("datasource.runAndUpdate"), {
              variant: "success",
            });
          } else {
            enqueueSnackbar(t("datasource.updated"), {
              variant: "success",
            });
          }
        }}
      />
      <Box mt={4}>
        <ListExecutions task={datasource} />
      </Box>
    </>
  );
}
