import { StorageAccount } from "@dashboard-v3/api";
import SelectField from "components/Forms/SelectField";
import { useTranslation } from "react-i18next";
import { StyledMenuItem } from ".";
import { Typography } from "@material-ui/core";

export default function SelectStorageAccount({
  accounts,
  value,
  onChange: emitChange,
}: {
  accounts: StorageAccount[];
  value: string;
  onChange: (accountId: string) => void;
}) {
  const { t } = useTranslation(["rules", "datasets"]);

  return (
    <SelectField
      allowEmpty={!value}
      value={value}
      required
      label={t("datasets:datasourceForm.cloudStorageAccount")}
      options={accounts}
      onChange={event => {
        emitChange(event.target.value as string);
      }}
      renderValue={value => {
        const account = accounts.find(account => account.id === value);
        if (!account) return "";
        return account.type === "DROPBOX" ? account.data.name : account.user;
      }}
      renderOption={option => {
        return (
          <StyledMenuItem key={option.id} value={option.id}>
            <Typography variant="caption">
              {t(`cloudStorage.header.subtitle.${option.type}`)}
            </Typography>
            <Typography>
              {option.type === "DROPBOX" ? option.data.name : option.user}
            </Typography>
          </StyledMenuItem>
        );
      }}
    />
  );
}
