import { StorageAccount, StorageProvider } from "@dashboard-v3/api";
import { Box, colors, Grid, Link, MenuItem } from "@material-ui/core";
import styled from "styled-components";
import { StyledPaper } from "components/Styled";
import { fetchStorageAccounts } from "pages/Integrations/api";
import { useTranslation } from "react-i18next";
import useSWR from "swr";
import FolderPathSelector from "./FolderPathSelector";
import { useState } from "react";
import produce, { Draft } from "immer";
import ButtonWithLoading from "components/ButtonWithLoading";
import apiRequest, { fetcher } from "utils/apiRequestWithErrorCode";
import { useSnackbar } from "notistack";
import {
  CreateDatasetDatasource,
  Dataset,
  DatasourceTask,
} from "@dashboard-v3/api";
import { useHistory } from "react-router-dom";
import { Skeleton } from "@material-ui/lab";
import FileOrFolder from "./FileOrFolder";
import ReplaceOrMerge from "./ReplaceOrMerge";
import SelectStorageAccount from "./SelectStorageAccount";
import SelectUseAs from "./SelectUseAs";
import SelectRepeat from "./SelectRepeat";

export type Task = {
  id: string;
  organizationId: string;
  action: "REPLACE" | "MERGE";
  isScheduled: boolean;
  datasetId: string;
  params: CloudStorageLoader;
};

export type CloudStorageLoader = {
  type: "FILE" | "FOLDER";
  parentId: string;
  parentName?: string;
  useAsKey: "id" | "name";
  useAsValue: "id" | "name";
  storageProvider: Omit<StorageProvider, "MXHMXHERO_STORAGE">;
  storageUser: string;
  storageAccountId: string;
};

export type DatasourceForm = Partial<
  Omit<CreateDatasetDatasource, "params">
> & {
  id?: string;
  datasetId: string;
  params: Partial<CloudStorageLoader>;
};

export default function Form({
  data,
  onSave,
}: {
  data: DatasourceForm;
  onSave?: (task: DatasourceTask) => void;
}) {
  const [form, setForm] = useState<DatasourceForm>(data);
  const [isSaving, setIsSaving] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const isEditing = Boolean(data.id);

  const { t } = useTranslation(["datasets", "common"]);

  const { data: storageAccounts } = useSWR<StorageAccount[]>(
    "/storage-accounts",
    fetchStorageAccounts
  );

  const { data: dataset } = useSWR<Dataset>(
    `/datasets/${data.datasetId}`,
    fetcher,
    {
      revalidateOnFocus: false,
      suspense: true,
      onError() {
        enqueueSnackbar(t("form.errors.fetchByIdError"), {
          variant: "error",
        });
        history.push("/datasets");
      },
    }
  );

  function updateForm(update: (draft: Draft<DatasourceForm>) => void) {
    return setForm(produce(update));
  }

  const canSave = Boolean(form.params.storageUser && form.params.parentId);
  const canDisable = Boolean(isEditing && data.repeatEvery > 0);

  async function save() {
    setIsSaving(true);
    try {
      let task;
      if (isEditing) {
        task = await apiRequest("PUT", `/datasources/${data.id}`, form);
      } else {
        task = await apiRequest(
          "POST",
          `/datasets/${form.datasetId}/datasources`,
          form
        );
      }
      onSave?.(task);
    } catch (error) {
      console.error("Error saving task:", error);
      enqueueSnackbar(t("common:errors.fetchError"), {
        variant: "error",
      });
    } finally {
      setIsSaving(false);
    }
  }

  async function disableSync() {
    setIsSaving(true);
    try {
      await apiRequest("PUT", `/datasources/${data.id}`, {
        ...form,
        repeatEvery: 0,
      });
    } catch (error) {
      console.error("Error saving task:", error);
      enqueueSnackbar(t("common:errors.fetchError"), {
        variant: "error",
      });
    } finally {
      setIsSaving(false);
    }
  }

  return (
    <Grid container>
      <Grid item lg={12}>
        <StyledPaper>
          <Box mb={5}>
            <Box fontSize="1.2rem">
              {t("datasourceForm.title")}{" "}
              <Link
                href={`/datasets/edit/${data.datasetId}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {dataset ? dataset.name : "..."}
              </Link>
            </Box>
            <Box mt="7px" color={colors.grey[600]}>
              {t("datasourceForm.subtitle", {
                interpolation: { escapeValue: false },
              })}
            </Box>
          </Box>

          <form>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                {storageAccounts ? (
                  <SelectStorageAccount
                    accounts={storageAccounts}
                    value={form.params.storageAccountId}
                    onChange={accountId => {
                      const storageAccount = storageAccounts.find(
                        account => account.id === accountId
                      );

                      updateForm(draft => {
                        draft.params.storageProvider = storageAccount.type;
                        draft.params.storageUser = storageAccount.user;
                        draft.params.storageAccountId = storageAccount.id;
                      });
                    }}
                  />
                ) : (
                  <Skeleton variant="rect" width="100%" height="55px" />
                )}
              </Grid>
              {form.params.storageProvider && (
                <>
                  <Grid item xs={12}>
                    <FolderPathSelector
                      label={t("datasourceForm.folderPathTitle")}
                      hint={t("datasourceForm.folderPathSubtitle")}
                      value={{
                        parentId: form.params.parentId,
                        parentName: form.params.parentName,
                      }}
                      onChange={(parentId: string, parentName?: string) => {
                        updateForm(draft => {
                          draft.params.parentId = parentId;
                          draft.params.parentName = parentName;
                        });
                      }}
                      storageProvider={form.params.storageProvider}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <ReplaceOrMerge
                      value={form.action}
                      onChange={value => {
                        updateForm(draft => {
                          draft.action = value;
                        });
                      }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <FileOrFolder
                      value={form.params.type}
                      onChange={newValue => {
                        updateForm(draft => {
                          draft.params.type = newValue;
                        });
                      }}
                    />

                    <SelectUseAs
                      title={t("datasourceForm.asKeyLabel")}
                      value={form.params.useAsKey}
                      fileOrFolder={form.params.type}
                      onChange={newValue => {
                        updateForm(draft => {
                          draft.params.useAsKey = newValue;
                        });
                      }}
                    />

                    <SelectUseAs
                      title={t("datasourceForm.asValueLabel")}
                      value={form.params.useAsValue}
                      fileOrFolder={form.params.type}
                      onChange={newValue => {
                        updateForm(draft => {
                          draft.params.useAsValue = newValue;
                        });
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <SelectRepeat
                      value={form.repeatEvery}
                      onChange={newValue =>
                        updateForm(draft => {
                          draft.repeatEvery = newValue;
                        })
                      }
                    />
                  </Grid>
                </>
              )}
            </Grid>

            <Box mt={4} display="flex" gridGap="10px">
              <ButtonWithLoading
                onClick={save}
                variant="contained"
                color="primary"
                disableElevation
                disabled={!canSave}
                loading={isSaving}
              >
                {t(saveLabel(form))}
              </ButtonWithLoading>
              {canDisable && (
                <ButtonWithLoading
                  onClick={disableSync}
                  variant="text"
                  color="secondary"
                  disableElevation
                  disabled={!canSave}
                  loading={isSaving}
                >
                  {t("datasourceForm.disableSync")}
                </ButtonWithLoading>
              )}
            </Box>
          </form>
        </StyledPaper>
      </Grid>
    </Grid>
  );
}

function saveLabel(data: DatasourceForm) {
  const isEditing = Boolean(data.id);
  const repeat = Boolean(data.repeatEvery);

  if (!isEditing) return "datasourceForm.save";
  if (isEditing && repeat) return "datasourceForm.update";
  if (isEditing && !repeat) return "datasourceForm.updateAndSync";
}

export const StyledMenuItem = styled(MenuItem)`
  display: block;
  opacity: 1 !important;
  cursor: ${({ disabled }) => disabled && "default"};
  pointer-events: ${({ disabled }) => disabled && "none"};
  p {
    opacity: ${({ disabled }) => disabled && "0.5"};
  }
  &:hover {
    background: ${({ disabled }) => disabled && "none"};
  }
`;
