import { TaskExecution } from "@dashboard-v3/api";
import { DatasourceTask } from "@dashboard-v3/api/src/types/datasets";
import { Box, ListItemText, Paper, Typography } from "@material-ui/core";
import ComponentList from "components/ComponentList";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import useSWR from "swr";
import { fetcher } from "utils/apiRequestWithErrorCode";

const MAX_RETRIES = 10;

export default function ListExecutions({ task }: { task: DatasourceTask }) {
  const { t } = useTranslation("datasets");

  const { data: executions, isLoading } = useSWR<TaskExecution[]>(
    `/datasources/${task.id}/executions`,
    fetcher
  );

  return (
    <>
      <Box mb={3} fontSize="1.2rem" fontWeight="500">
        {t("executions.title")}
        <Box component="span" ml={2}>
          <Typography variant="caption" color="textSecondary">
            {t("executions.subtitle")}
          </Typography>
        </Box>
      </Box>

      <ComponentList
        list={executions}
        loading={isLoading}
        emptyMsg="No items found"
        renderItems={(execution: TaskExecution) => (
          <StyledPaper key={execution.id}>
            <Item execution={execution} />
          </StyledPaper>
        )}
      />
    </>
  );
}

const Item = ({ execution }: { execution: TaskExecution }) => {
  const { t } = useTranslation("datasets");

  switch (execution.status) {
    case "DONE":
      return (
        <ListItemText
          primary={<Typography>{t("executions.done")}</Typography>}
          secondary={t("executions.endsAt", {
            time: new Date(execution.endsAt).toLocaleString(),
            interpolation: { escapeValue: false },
          })}
        />
      );

    case "PROCESSING":
      return (
        <ListItemText
          primary={t("executions.processing")}
          secondary={
            <>
              <Box display="inline">
                Started at {new Date(execution.startedAt).toLocaleString()}
              </Box>
              {Boolean(execution.retryContext?.currentRetry) && (
                <Box display="inline" ml={3}>
                  {t("executions.retryN", {
                    times: execution.retryContext?.currentRetry,
                  })}
                </Box>
              )}
            </>
          }
        />
      );

    case "WAITING_RETRY":
      return (
        <ListItemText
          primary={t("executions.waitingRetry")}
          secondary={
            <>
              <Box display="inline">
                {t("executions.failedAt", {
                  time: new Date(
                    execution.retryContext.lastFailAt
                  ).toLocaleString(),
                  interpolation: { escapeValue: false },
                })}
              </Box>
              {Boolean(execution.retryContext?.currentRetry) && (
                <Box display="inline" ml={3}>
                  {t("executions.retryXofY", {
                    x: execution.retryContext?.currentRetry + 1,
                    y: MAX_RETRIES,
                  })}
                </Box>
              )}
              <Box ml={3} display="inline">
                {t("executions.retryExplain")}
              </Box>
            </>
          }
        />
      );

    case "ERROR":
      return (
        <ListItemText
          primary="Finished with errors"
          secondary={
            <>
              <Box display="inline">
                {t("executions.endsAt", {
                  time: new Date(execution.endsAt).toLocaleString(),
                  interpolation: { escapeValue: false },
                })}
              </Box>
              {execution.errorDescription && (
                <Box display="inline" ml={3}>
                  {t("executions.error", { error: execution.errorDescription })}
                </Box>
              )}
            </>
          }
        />
      );

    default:
      break;
  }
};

const StyledPaper = styled(Paper)`
  display: grid;
  padding: 16px 25px;
  margin-bottom: 0.8rem;
`;
