import { useParams, useHistory } from "react-router-dom";
import Form, { DatasourceForm } from "./Form";
import SectionHeader from "components/SectionHeader";
import { Button } from "components/Forms/StyledComponents";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";

export default function CreateDatasource() {
  const history = useHistory();
  const { id: datasetId } = useParams();

  const { t } = useTranslation("datasets");
  const { enqueueSnackbar } = useSnackbar();

  const form: DatasourceForm = {
    action: "REPLACE",
    repeatEvery: 0,
    datasetId,
    params: {
      type: "FILE",
      parentId: "",
      useAsKey: "id",
      useAsValue: "name",
      storageUser: "",
      storageAccountId: "",
    },
  };

  return (
    <>
      <SectionHeader
        title="Datasource"
        sideBtn={
          <Button
            wording="Back to list"
            onClick={() => history.push("/datasets")}
          />
        }
      />
      <Form
        data={form}
        onSave={task => {
          enqueueSnackbar(t("datasource.runAndUpdate"), {
            variant: "success",
          });
          history.push(`/datasources/${task.id}`);
        }}
      />
    </>
  );
}
