import { StorageProvider, TargetType } from "@dashboard-v3/api";
import { State } from "../types";
import produce from "immer";
import { SecurityOption } from "pages/Rules/CloudStorage/types";

export function checkFolderCollaboration(
  state: State,
  payload: CheckFolderCollaboration["payload"]
): State {
  return produce(state, draft => {
    if (payload.targetType) {
      const { targetType } = payload;
      const isValidTarget = ["MAIL_BOX", "EMAIL_FLOW"].includes(targetType);
      draft.checks.canCollaborateFolder = isValidTarget;
      return;
    }

    if (payload.provider) {
      const { provider } = payload;
      draft.checks.canCollaborateFolder = provider !== "EGNYTE";
      return;
    }

    if (payload.securityOption) {
      const { allowFolderCollaboration } = payload.securityOption;
      draft.checks.canCollaborateFolder = allowFolderCollaboration;
      return;
    }

    if (payload.allManagedUserIds) {
      draft.checks.canCollaborateFolder = false;
      return;
    }

    draft.checks.canCollaborateFolder = true;
  });
}

export type CheckFolderCollaboration = {
  type: "checkFolderCollaboration";
  payload: {
    targetType?: TargetType;
    provider?: StorageProvider;
    securityOption?: SecurityOption;
    allManagedUserIds?: boolean;
  };
};
